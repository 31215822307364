import axios from 'axios'
import {ElMessage} from 'element-plus'
import {getAccessToken} from './auth'
import {getBaseApiUrl} from "./common";


// 请求拦截器
axios.interceptors.request.use((config) => {
    let access_token = getAccessToken()

    if(access_token){
        config.headers.Authorization = "Bearer " + access_token
    }

    config.baseURL = getBaseApiUrl()

    return config
})

// 响应拦截器
axios.interceptors.response.use((response) => {

    if(response.data.err_code > 0){
        ElMessage.warning(response.data.err_msg)
    }

    return response.data
}, (error) => {
    ElMessage.error('服务器开小差了~')
    // 对响应错误做点什么
    return Promise.reject(error)
})

export default axios
