import qs from 'qs'
/**
 *
 * @param message
 * @param code
 * @constructor
 */
export function CommonException(message, code)
{
    this.message = message;
    this.code = code;
}

export function getBaseApiUrl() {
    return process.env.VUE_APP_BASE_URL
}

/**
 * 获取基础api地址
 * @param url
 * @param query
 * @returns {string}
 */
export function getApiUrl (url = '', query = []) {
    if (url.substring(0, 1) === '/') {
        url = url.substring(1)
    }

    let fullurl = getBaseApiUrl()  + '/' + url

    if (query) {
        fullurl += '?' + qs.stringify(query)
    }

    return fullurl
}

/**
 * 调整尺寸
 * @param img
 * @param width
 * @param height
 * @returns {string}
 */
export function resize(img, width = 100, height = 100)
{
    if (img) {
        return img + '?imageView2/1/w/'+width+'/h/' + height
    }

    return ''
}
